import { render, staticRenderFns } from "./VacanciesPage.vue?vue&type=template&id=c796cf30&scoped=true&"
import script from "./VacanciesPage.vue?vue&type=script&lang=js&"
export * from "./VacanciesPage.vue?vue&type=script&lang=js&"
import style0 from "./VacanciesPage.vue?vue&type=style&index=0&id=c796cf30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c796cf30",
  null
  
)

export default component.exports