<template>
  <div
    class="vacancies-page container-wrapper"
    :class="{ 'vacancies-page--sent': isSent }"
  >
    <v-container v-if="!isSent">
      <v-row>
        <v-col class="text-center mb-4 mb-lg-5">
          <h1>Find your dream job and join our team!</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="custom-file-input">
            <label
                for="upload-photo"
                class="custom-file-input__label"
            >
              <span
                  v-if="!uploadFile"
                  class="custom-file-input__label_upload"
              >
                <img src="@/assets/icons/upload.svg" alt="Upload">
                <span>Drag and drop here</span>
              </span>
              <span v-else>
                {{ uploadFile.name }}
              </span>
            </label>
            <input
                id="upload-photo"
                class="custom-file-input__button"
                type="file"
                accept="application/pdf, .doc, .docx"
                @change="handleFileChange"
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center mt-4 mt-lg-5">
          <button
              class="green-btn vacancies-page__button px-8"
              :disabled="!uploadFile"
              @click="sendDataToDB"
          >
            Apply
          </button>
        </v-col>
      </v-row>
    </v-container>
    <div v-else class="text-center">
      <h1 class="mb-4">Thank you!</h1>
      <div>You’ve successfully applied for the job.</div>
      <div>We’ll get back to you shortly.</div>
    </div>

    <v-snackbar
        v-model="fileSnackbar"
        :timeout="fileTimeout"
        color="error"
    >
      The file size should not exceed 1 MB.

      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            v-bind="attrs"
            @click="fileSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "VacanciesPage",
  data () {
    return {
      uploadFile: null,
      isSent: false,
      rcaptchaHash: '',

      fileSnackbar: false,
      fileTimeout: 2000,
    }
  },
  methods: {
    async recaptcha () {
      await this.$recaptchaLoaded()
      this.rcaptchaHash = await this.$recaptcha('job')
      console.log('captcha', this.rcaptchaHash)
    },
    handleFileChange (e) {
      let file = e.target.files[0]
      if (file && file.size  > 1000000) {
        this.fileSnackbar = true
        document.getElementById('upload-photo').value = ''
      } else if (file) {
        this.uploadFile = e.target.files[0]
      }
    },
    async postData (formData) {
      const res = await fetch('https://api.ari.company/api/form/jobs', {
        method: 'POST',
        body: formData
      });
      return await res.json();
    },
    async sendDataToDB () {
      await this.recaptcha()

      if (this.uploadFile && this.rcaptchaHash !== '') {
        const formData = new FormData()
        formData.append('file', this.uploadFile)
        formData.append('rcaptcha', this.rcaptchaHash)

        this.postData(formData)
          .then(data => {
            if (data.status === 'OK') {
              this.isSent = true
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vacancies-page {
  flex: 1 0 auto;
  padding-top: 120px;
  padding-bottom: 160px;

  .custom-file-input {
    width: 260px;
    height: 120px;

      &__label_upload span {
          width: 100%;
          margin-top: 5px;
      }
  }

  &__button {
    width: 100%;
  }
  &.vacancies-page--sent {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      font-weight: 400;
      font-size: 14px;
    }
  }

  @media (min-width: $mobile-small) {
    &__button {
      width: 230px;
    }
  }

  @media (min-width: $pad-medium) {
    padding-top: 140px;
    padding-bottom: 220px;

    .custom-file-input {
      width: 320px;
      height: 150px;
    }
  }
}
</style>
