import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vacancies-page container-wrapper",class:{ 'vacancies-page--sent': _vm.isSent }},[(!_vm.isSent)?_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"text-center mb-4 mb-lg-5"},[_c('h1',[_vm._v("Find your dream job and join our team!")])])],1),_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"custom-file-input"},[_c('label',{staticClass:"custom-file-input__label",attrs:{"for":"upload-photo"}},[(!_vm.uploadFile)?_c('span',{staticClass:"custom-file-input__label_upload"},[_c('img',{attrs:{"src":require("@/assets/icons/upload.svg"),"alt":"Upload"}}),_c('span',[_vm._v("Drag and drop here")])]):_c('span',[_vm._v(" "+_vm._s(_vm.uploadFile.name)+" ")])]),_c('input',{staticClass:"custom-file-input__button",attrs:{"id":"upload-photo","type":"file","accept":"application/pdf, .doc, .docx"},on:{"change":_vm.handleFileChange}})])])],1),_c(VRow,[_c(VCol,{staticClass:"text-center mt-4 mt-lg-5"},[_c('button',{staticClass:"green-btn vacancies-page__button px-8",attrs:{"disabled":!_vm.uploadFile},on:{"click":_vm.sendDataToDB}},[_vm._v(" Apply ")])])],1)],1):_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"mb-4"},[_vm._v("Thank you!")]),_c('div',[_vm._v("You’ve successfully applied for the job.")]),_c('div',[_vm._v("We’ll get back to you shortly.")])]),_c(VSnackbar,{attrs:{"timeout":_vm.fileTimeout,"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.fileSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.fileSnackbar),callback:function ($$v) {_vm.fileSnackbar=$$v},expression:"fileSnackbar"}},[_vm._v(" The file size should not exceed 1 MB. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }